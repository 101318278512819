import { Panel } from "primereact/panel";

import styles from "./styles.module.css";
import { Description } from "../Description";

export type DadosProps = {
  label: string;
  value: string | number | null;
};

export type ListDescriptionsProps = {
  categoria: string;
  dados: DadosProps[];
};

export function ListDescriptions({ categoria, dados }: ListDescriptionsProps) {
  return (
    <div className={styles.wrapper} key={categoria}>
      <Panel header={categoria}>
        <div className={styles.gridContainer}>
          {dados.map(({ label, value }) => (
            <Description key={label} label={label} value={value} />
          ))}
        </div>
      </Panel>
    </div>
  );
}
