import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useEffect, useRef, useState } from "react";
import { InputMask } from "primereact/inputmask";
import { Stepper } from 'primereact/stepper';
import { StepperPanel, StepperPanelContext, StepperPanelProps } from 'primereact/stepperpanel';
import { Dropdown } from "primereact/dropdown";
import getEstado from "../../service/getEstado";
import getMunicipio from "../../service/getMunicipio";
import getVeiculoVendaTipoSolcitante from "../../service/getVeiculoVendaTipoSolicitante/getVeiculoVendaTipoSolicitanteService";
import { InputNumber } from "primereact/inputnumber";
import getVeiculoVendaTipoDocumentoVeiculo from "../../service/getVeiculoVendaTipoDocumentoVeiculo";
import getVeiculoVendaTipoPessoa from "../../service/getVeiculoVendaTipoPessoa";
export default function VeiculoVendaCadastrar({veiculoVenda, onClose, onSave, msgs}:{veiculoVenda:any, onClose: () => void, onSave: (veiculoVenda:any) => void, msgs:any}) {
  const { t } = useTranslation();
  const stepperRef = useRef<any>(null);
  const [tiposSolicitante, setTiposSolicitante] = useState<any>([]);
  const [tiposPessoa, setTiposPessoa] = useState<any>([]);
  const [tiposDocumentoVeiculo, setTiposDocumentoVeiculo] = useState<any>([]);
  const [estados, setEstados] = useState<any>([]);
  const [municipios, setMunicipios] = useState<any>([]);
  const [estadoFilter, setEstadoFilter] = useState<string|null>(null);
  const [tipoPessoaProprietario, setTipoPessoaProprietario] = useState<string|null>(null);
  const [tipoPessoaComprador, setTipoPessoaComprador] = useState<string|null>(null);
  const [passo1, setPasso1] = useState()
  const [passo2, setPasso2] = useState()
  const [passo3, setPasso3] = useState()

  const defaultValues = {
    placa: '',
    renavam: '',
    crv: '',
    tipoSolicitante: null,
    tipoDocumentoVeiculo: null,
    dataVenda: null,
    dataEmissaoCrv: null,
    valor: null,
    estado: null,
    estadoCrv: null,
    municipio: null,
    anoFabricacao: null,
    anoModelo: null,
    codigoCrv: null, 
    nomeProprietario: '',
    emailProprietario: '',
    documentoProprietario: null,
    tipoPessoaProprietario: null,
    nomeComprador: '',
    emailComprador: '',
    documentoComprador: null,
    tipoPessoaComprador: null,
    viaCrv: null,
    modelo: '',
    marca: '',
    quilometragem: ''
  };
 
  
  const {
      control,
      formState: { errors},
      handleSubmit,
      setValue
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      setPasso1(data);
      if(stepperRef && stepperRef.current){
        stepperRef.current.nextCallback()
      }
      
  };

  const onSubmit2 = (data:any) => {
    setPasso2(data);
    if(stepperRef && stepperRef.current){
      stepperRef.current.nextCallback()
    }
    
};

const onSubmit3 = (data:any) => {
  setPasso3(data);
  if(stepperRef && stepperRef.current){
    stepperRef.current.nextCallback()
  }
  
};

 
  const save = (data:any) => {
    const vei = {
      id: veiculoVenda.id? veiculoVenda.id : undefined,
      placa: data.placa,
      marca: data.marca,
      modelo: data.modelo
    }
    window.scrollTo(0, 0);
    onSave(vei);
  }


  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  useEffect(() => {
    getEstado().then( (data) => setEstados(data))
    getVeiculoVendaTipoSolcitante().then( (data) => setTiposSolicitante(data))
    getVeiculoVendaTipoDocumentoVeiculo().then((data) => setTiposDocumentoVeiculo(data))
    getVeiculoVendaTipoPessoa().then((data) => setTiposPessoa(data))
  }, [])
  

  return (
    <>
      <Stepper ref={stepperRef} linear headerPosition="bottom">
        <StepperPanel  header="Local/Data">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
            <div>
              <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="tipoSolicitante"
                          control={control}
                          rules={{ required: 'Solicitante é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoSolicitante })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="tipoSolicitante" className="font-bold block mb-2 required">{t('solicitante')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={tiposSolicitante} optionLabel="label" placeholder="Selecione Solicitante" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                  
                  
                  </div>
              </div>
              <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="dataVenda"
                          control={control}
                          rules={{ required: 'Data Venda é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataVenda })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="dataVenda" className="font-bold block mb-2 required">{t('dataVenda')} </label>
                                    <Calendar id='dataVenda' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="valor"
                          control={control}
                          rules={{ required: 'Valor é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.valor })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="valor" className="font-bold block mb-2 required">{t('valorVeiculo')} </label>
                                      <InputNumber style={{width: '100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
                  
                </div>
                <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="estado"
                          control={control}
                          rules={{ required: 'Estado é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.estado })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="estado" className="font-bold block mb-2 required">{t('uf')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => {getMunicipio({uf:e.value}).then((data) => setMunicipios(data)); setEstadoFilter(e.value); field.onChange(e.value)}} options={estados} optionLabel="nome" optionValue="sigla" placeholder="Selecione Estado" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="municipio"
                          control={control}
                          rules={{ required: 'Município é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.municipio })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="municipio" className="font-bold block mb-2 required">{t('cidade')} </label>
                                    <Dropdown disabled={!estadoFilter} style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={municipios} optionLabel="nome" placeholder="Selecione Cidade" 
                                    filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
              </div>
              
              
            </div>
            <br></br>
            <br></br>
          
            <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
                <Button icon="pi pi-arrow-right" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Avançar" type="submit"   />
                
            </div> 
        </form>
        </StepperPanel>
        <StepperPanel header="Dados do Veículo">
          <form onSubmit={handleSubmit(onSubmit2)} className="flex flex-column gap-2">
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="placa"
                          control={control}
                          rules={{ required: 'Data Venda é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.placa })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="placa" className="font-bold block mb-2 required">{t('placa')} </label>
                                    <InputMask id={field.name}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="aaa-9*99" onChange={(e) => field.onChange(e.target.value)}></InputMask>
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="renavam"
                          control={control}
                          rules={{ required: 'Renavam é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.renavam })}></label>
                                  <span className="">
                                  <div>
                                      <label htmlFor="renavam" className="font-bold block mb-2 required">{t('renavam')} </label>
                                      <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                  </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
            </div>
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="tipoDocumentoVeiculo"
                          control={control}
                          rules={{ required: 'Tipo Documento Veículo é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoDocumentoVeiculo })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="tipoDocumentoVeiculo" className="font-bold block mb-2 required">{t('tipoDocumentoVeiculo')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => field.onChange(e.value)} options={tiposDocumentoVeiculo} optionLabel="label" placeholder="Selecione Tipo Documento Veículo" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="crv"
                          control={control}
                          rules={{ required: 'CRV é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.crv })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="crv" className="font-bold block mb-2 required">{t('crv')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
              </div>
              <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                  <Controller
                          name="dataEmissaoCrv"
                          control={control}
                          rules={{ required: 'Data Venda é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.dataEmissaoCrv })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="dataEmissaoCrv" className="font-bold block mb-2 required">{t('dataEmissaoCrv')} </label>
                                    <Calendar id='dataEmissaoCrv' value={field.value}  onChange={(e) => field.onChange(e.target.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="estadoCrv"
                          control={control}
                          rules={{ required: 'Estado CRV é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.estadoCrv })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="estadoCrv" className="font-bold block mb-2 required">{t('uf') + ' ' + t('crv')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) => {field.onChange(e.value)}} options={estados} optionLabel="nome" optionValue="sigla" placeholder="Selecione UF CRV" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
            </div>
            <div className="grid">
              <div className="col">
                <Controller
                      name="viaCrv"
                      control={control}
                      rules={{ required: 'Via CRV é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.viaCrv })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="viaCrv" className="font-bold block mb-2 required">{t('viaCrv')} </label>
                                  <InputNumber style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="codigoCrv"
                      control={control}
                      rules={{ required: 'Código CRV é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.codigoCrv })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="codigoCrv" className="font-bold block mb-2 required">{t('codigoSegurancaCrv')} </label>
                                  <InputNumber style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
            </div>
            <div className="grid">
              <div className="col">
                <Controller
                      name="anoModelo"
                      control={control}
                      rules={{ required: 'Ano Modelo é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.anoModelo })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="anoModelo" className="font-bold block mb-2 required">{t('anoModelo')} </label>
                                  <InputNumber style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              <div className="col">
                <Controller
                      name="anoFabricacao"
                      control={control}
                      rules={{ required: 'Ano Fabricação é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.anoFabricacao })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="anoFabricacao" className="font-bold block mb-2 required">{t('anoFabricacao')} </label>
                                  <InputNumber style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.value)} size={4} maxLength={4} max={9999}  useGrouping={false}  />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
            </div>              
            <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
              <Button label="Voltar" style={{marginRight:"1rem", backgroundColor:'#183462'}} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
              <Button icon="pi pi-arrow-right" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Avançar" type="submit"   />
                  
            </div> 
          </form>
        </StepperPanel>
        <StepperPanel header="Proprietário Atual">
          <form onSubmit={handleSubmit(onSubmit3)} className="flex flex-column gap-2">
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="tipoPessoaProprietario"
                          control={control}
                          rules={{ required: 'Tipo Pessoa é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoPessoaProprietario })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="tipoPessoaProprietario" className="font-bold block mb-2 required">{t('tipoPessoa')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) =>{setTipoPessoaProprietario(e.value); field.onChange(e.value)}} options={tiposPessoa} optionLabel="label" placeholder="Selecione Tipo Pessoa" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col p-fluid flex flex-wrap">
                    <Controller
                          name="documentoProprietario"
                          control={control}
                          rules={{ required: 'Documento é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.documentoProprietario })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="documentoProprietario" className="font-bold block mb-2 required">{t('documento')} </label>
                                    {tipoPessoaProprietario && tipoPessoaProprietario === 'FISICA' ? (<InputMask id={field.name}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="999.999.999-99" onChange={(e) => field.onChange(e.target.value)}></InputMask>) : (<InputMask id={field.name}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="99.999.999\9999-99" onChange={(e) => field.onChange(e.target.value)}></InputMask>)}
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
              </div>
            <div className="grid">
              <div className="col">
                    <Controller
                          name="nomeProprietario"
                          control={control}
                          rules={{ required: 'Nome é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.nomeProprietario })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="nomeProprietario" className="font-bold block mb-2 required">{t('nome')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="emailProprietario"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.emailProprietario })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="emailProprietario" className="font-bold block mb-2">{t('email')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
            </div>
            <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
              <Button label="Voltar" style={{marginRight:"1rem", backgroundColor:'#183462'}} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
              <Button icon="pi pi-arrow-right" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Avançar" type="submit"   />
                  
            </div> 
          </form>
        </StepperPanel>
        <StepperPanel header="Comprador">
        <form onSubmit={handleSubmit(onSubmit3)} className="flex flex-column gap-2">
            <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                    
                      <Controller
                          name="tipoPessoaComprador"
                          control={control}
                          rules={{ required: 'Tipo Pessoa é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.tipoPessoaComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="tipoPessoaComprador" className="font-bold block mb-2 required">{t('tipoPessoa')} </label>
                                    <Dropdown style={{width: '50%'}} value={field.value}  onChange={(e) =>{setTipoPessoaComprador(e.value); field.onChange(e.value)}} options={tiposPessoa} optionLabel="label" placeholder="Selecione Tipo Pessoa" 
                                  filter className="w-full md:w-14rem" />  
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                        
                  
                  </div>
                  <div className="col p-fluid flex flex-wrap">
                    <Controller
                          name="documentoComprador"
                          control={control}
                          rules={{ required: 'Documento é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.documentoComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="documentoComprador" className="font-bold block mb-2 required">{t('documento')} </label>
                                    {tipoPessoaComprador && tipoPessoaComprador === 'FISICA' ? (<InputMask id={field.name}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="999.999.999-99" onChange={(e) => field.onChange(e.target.value)}></InputMask>) : (<InputMask id={field.name}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="99.999.999\9999-99" onChange={(e) => field.onChange(e.target.value)}></InputMask>)}
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                  
                  </div>
              </div>
            <div className="grid">
              <div className="col">
                    <Controller
                          name="nomeComprador"
                          control={control}
                          rules={{ required: 'Nome é Obrigatório' }}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.nomeComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="nomeComprador" className="font-bold block mb-2 required">{t('nome')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
                  <div className="col">
                    <Controller
                          name="emailComprador"
                          control={control}
                          render={({ field, fieldState }) => (
                              <>
                                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.emailComprador })}></label>
                                  <span className="">
                                  <div>
                                    <label htmlFor="emailComprador" className="font-bold block mb-2">{t('email')} </label>
                                    <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                    </div>
                                  </span>
                                  {getFormErrorMessage(field.name)}
                              </>
                          )}
                      />
                    
                  
                  </div>
            </div>
            <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
              <Button label="Voltar" style={{marginRight:"1rem", backgroundColor:'#183462'}} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
              <Button icon="pi pi-arrow-right" style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Avançar" type="submit"   />
                  
            </div> 
          </form>
        </StepperPanel>
        <StepperPanel header="Finalização"></StepperPanel>
      </Stepper>
      <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
    
        <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
      </div>

    </>
  )
}


