import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Tree, TreeCheckboxSelectionKeys, TreeExpandedKeysType, TreeMultipleSelectionKeys } from 'primereact/tree';
import { useGlobalState } from '../../../components/globalState/GlobalStateProvider';
import { Divider } from 'primereact/divider';
import { FilterWeb } from '../../../interface/FilterWeb';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';


export default function FiltroAutomotiva({
    onProcess,
    onClose,
    msgs
  }: {
    onProcess: (filterWeb : FilterWeb) => void
    onClose: () => void,
    msgs:any
  }) {
  const { t } = useTranslation();
  const {state} = useGlobalState();
  const [placa, setPlaca] = useState<string>("");
  const [chassi, setChassi] = useState<string>("");
  const [motor, setMotor] = useState<string>("");
  const [selectedKeys, setSelectedKeys] = useState<string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | any | null>(null); 
  const [selectedOptionCheck, setSelectedOptionCheck] = useState<any[]>([]);
  const placaObrigatorio = [
    "AUTOMOTIVA_AGREGADO",
    "AUTOMOTIVA_RENAINF",
    "AUTOMOTIVA_LEILAO"
  ] 
  const placaChassiObrigatorio = [
    "AUTOMOTIVA_PRECIFICADOR",
    "AUTOMOTIVA_GRAVAMESIMPLES",
    "AUTOMOTIVA_HISTORICOPROPRIETARIO",
    "AUTOMOTIVA_RENAJUD",
    "AUTOMOTIVA_BASEESTADUAL",
    "AUTOMOTIVA_BASERENAVAM",
    "AUTOMOTIVA_DECODIFICADOR",
    "AUTOMOTIVA_ONLINE",
    "AUTOMOTIVA_PERDATOTAL"

  ] 

  const expandNode = (node:any, _expandedKeys:any) => {
    if (node.children && node.children.length) {
        _expandedKeys[node.key] = true;

        for (let child of node.children) {
            expandNode(child, _expandedKeys);
        }
    }
  };

  
  const createOptions = (optionElement : any) =>{
    const option : any[] = [];

    optionElement.permissoes.filter((f: {
      tipoConsulta: string; 
    }) => f.tipoConsulta ===  'AUTO')
    .sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } )
    .map((element: any) => {
        option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes ? createPermissoesFilhos(element.permissoes.filter((f: {
          tipoConsulta: string; 
        }) => f.tipoConsulta ===  'AUTO')) : []});
            });
      
    
  
            expandAll(option)      
    return option;
  }

  const expandAll = (option:any) => {
    let _expandedKeys = {};

    for (let node of option) {
        expandNode(node, _expandedKeys);
    }

   return _expandedKeys;
};

  const createPermissoesFilhos = (permissoes:any[]) => {
    const option : any[] = [];
    if(permissoes){
      permissoes
      .sort( (a:any,b:any) => {
        {
          if(!a.ordenacao){
            return 1;
          }
  
          if(!b.ordenacao){
            return -1;
          }
  
          if (a.ordenacao > b.ordenacao) {
            return 1;
          }
          if (a.ordenacao < b.ordenacao) {
            return -1;
          }
          return 0;
        }
      } )
      .forEach((element: {
        children: any; label: string; value: string; permissoes:any[]  
      }) => {
        option.push({label:element.label, key:element.value, Collapse:true, children: element.permissoes  ? createPermissoesFilhos(element.permissoes.filter((f: {
          tipoConsulta: string; 
        }) => f.tipoConsulta === 'AUTO')) : []});
      });
    }
    return option;
  }

  const mensagemErroFiltro = (mensagem : string) => {
    window.scrollTo(0, 0);
    msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: mensagem, closable: false }
        
      ]);
  }

  const valid = (filteWeb :FilterWeb) => {
    if(filteWeb.consultas.length === 0){
       mensagemErroFiltro('Opções de consulta obrigatório')
       return false;
    }

    if(filteWeb.consultas.includes("AUTOMOTIVA_MOTOR") && !filteWeb.motor){
        mensagemErroFiltro('Número motor obrigatório')
       return false;
    }

    let retorno = true;
    filteWeb.consultas.forEach((c:string) => {
      if(placaObrigatorio.includes(c) && (!filteWeb.placa || filteWeb.placa.length === 0)){
        mensagemErroFiltro('Placa obrigatório')
        retorno = false;
        return false;
      }
    })
    filteWeb.consultas.forEach((c:string) => {
      if(placaChassiObrigatorio.includes(c) && (!filteWeb.placa || filteWeb.placa.length === 0) && (!filteWeb.chassi || filteWeb.chassi.length === 0)){
        mensagemErroFiltro('Placa ou Chassi obrigatório')
        retorno = false;
        return false;
      }
    })


    return retorno;
  }

  const processConsulta = () => {

    let opcoes: string[] = [];
    if(selectedKeys){
      Object.getOwnPropertyNames(selectedKeys).map((item) => opcoes.push(item));
    }
   
    selectedOptionCheck.map((item) => opcoes.push(item.value));
    const filteWeb :FilterWeb = {
      documento: null,
      placa: placa,
      chassi: chassi,
      motor: motor, 
      consultas: opcoes,
      valor: null,
      arrojado: null,
      usual:null,
      conservador: null,
      indicadores: [],
      nomeRazao:null,
      uf:null,
      cidade:null,
      dataNascimento:null
    };
    if(valid(filteWeb)){
      onProcess(filteWeb);
    }
   
  }

  const nodeTemplate = (node: any, options: { className: string | undefined; }) => {
    let label = <span>{node.label}</span>;
    if (node.children.length !== 0) {
       label = <b>{node.label}</b>;
    }
    if(node.key.includes('AUTOMOTIVA_MOTOR') && selectedKeys && Object.getOwnPropertyNames(selectedKeys)?.includes('AUTOMOTIVA_MOTOR') ){
      return <>
      <Fieldset onClick={(e)=> e.stopPropagation()} legend="Motor">
        <InputText id="motor" value={motor} onChange={(e)=>{setMotor(e.target.value);}}  placeholder="Motor" required></InputText>
      </Fieldset>
      </>
    }
    return label;
  }

  return (<>
    <Panel header={t('consultaAutomotiva')}>
        <div className=" p-fluid flex flex-wrap gap-3">
            <div className="flex-auto">
                <label htmlFor="placa" className="font-bold block mb-2">Placa</label>
                <InputMask id="placa" value='placa' onBlur={(e)=>{setPlaca(e.target.value);}} mask="aaa-9*99" placeholder="Placa" required></InputMask>
            </div>
            <br></br>
            <div className="flex-auto">
                <label htmlFor="chassi" className="font-bold block mb-2">Chassi</label>
                <InputText id="chassi" value={chassi} onChange={(e)=>{setChassi(e.target.value);}}  placeholder="Chassi" required></InputText>
            </div>
            <br></br>
            
            {state.user.consultas.filter((f: { tipoConsulta: string; }) => f.tipoConsulta === 'AUTO').sort( (a:any,b:any) => {
      {
        if(!a.ordenacao){
          return 1;
        }

        if(!b.ordenacao){
          return -1;
        }

        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        return 0;
      }
    } ).map((_item: any) => {
                    return ( <div>
                        <b>{_item.label}</b>
                        <div key={_item.value} className="flex flex-column gap-3">
                            <br></br>
                            <Tree nodeTemplate={nodeTemplate} expandIcon={undefined} collapseIcon={undefined} expandedKeys = {expandAll(createOptions(_item))} value={createOptions(_item)} selectionMode="checkbox" selectionKeys={selectedKeys} onSelectionChange={(e) => {setSelectedKeys(e.value);  }} style={{border:'none', marginLeft:'-2.0rem', background:'transparent'}}  />
                        </div>
                        <br></br>
                        <Divider />
                        <br></br>
                        </div>);
                })}
         
        </div>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Consultar"  onClick={processConsulta} />
            
        </div> 
            
    </Panel>
   
    </>
  )
}
