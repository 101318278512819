import { useRef, useState } from 'react';
import logo from '../../assets/logo.png';
import './Login.css';
import logoCreditBureau from '../../assets/logoultra.jpeg';
import getUser from '../../service/getUser/getUserService';
import postLogin from '../../service/postLogin/postLoginService';
import { UserCredentials } from '../../interface/UserCredentials';
import { GlobalSaveData } from "../../components/globalState/GlobalSaveData";
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { Password } from 'primereact/password';
import getRelatorioNotificacao from '../../service/getRelatorioNotificacao';
import { Messages } from 'primereact/messages';



export default function Page() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const submit = () => {
    const userCredential:UserCredentials ={login:login, password:password};

    setTimeout(() =>{
      postLogin(userCredential)
              .then((data) => {
            
                GlobalSaveData("token", data)
                setTimeout(() =>{
                  getRelatorioNotificacao().then((data) => {
                    GlobalSaveData("relatorios", data);
                  });
                },100);
                
                setTimeout(() =>{
                  getUser().then((user) => {
                    GlobalSaveData("user", user);
                    setTimeout(() =>{window.location.href= (user.urlRedirect && user.urlRedirect !== 'null'? user.urlRedirect : '/creditbureau/home')},100);
                  }).catch((error) => {
                    msgs.current?.clear();
                    msgs.current?.show([
                        { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: 'Login ou Senha inválidos', closable: false }
                      
                    ]);
                  })
                },100);
                
              })
              .catch((error) => {
                msgs.current?.clear();
                msgs.current?.show([
                    { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: 'Login ou Senha inválidos', closable: false }
                  
                ]);
              })
            },1000);
  }
  return (
    <>
    <div className='main'>
      <div>
        <div className='logo_login'>
          <img src={logoCreditBureau} alt="logo" className='imageLogo' />
        </div>
        <div className='card-login card'>
          <img src={logo} alt="logo" className='imageLogo' />
          <div  className="login_form">
            <Messages ref={msgs} />
            <h2>Seja bem-vindo</h2>
            <form className="input-container">
                <div className="flex-auto">
                    <label htmlFor="login" className="font-bold block mb-2 required">{t('login')} </label>
                    <InputText id="login" onChange={(e)=>setLogin(e.target.value)} style={{width: '20rem'}} required></InputText>
                </div>
                <br></br>
                <div className="flex-auto">
                    <label htmlFor="login" className="font-bold block mb-2 required">{t('senha')} </label>
                    <Password  onChange={(e) => setPassword(e.target.value)} style={{width: '20rem'}} feedback={false} toggleMask />
                </div>
                
               
                <button type='button' onClick={submit} className="btn-secondary">
                    Entrar
                </button>
                <a href="/creditbureau/recuperacao" className="recovery">Esqueci minha senha</a>
                <a href={require('../../assets/privacidade.pdf')} target="_blank" className="privacity">Política de Privacidade</a>
            </form>
          </div>
        </div>
      </div>
    </div>
      
       
    </>
  )
}
