import { useRef, useState } from 'react';
import logo from '../../assets/logo.png';
import logoCreditBureau from '../../assets/logoultra.jpeg';
import postRecuperarSenha from '../../service/postRecuperarSenha/postRecuperarSenhaService';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { Password } from 'primereact/password';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';


export default function RecuperarSenha() {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const navigate = useNavigate();
  
  const submit = () => {
    if(email){
      setTimeout(() =>{
        postRecuperarSenha(email)
                .then((data) => {
                  msgs.current?.clear();
                  msgs.current?.show([
                      { sticky: false, life: 100000, severity: 'info', summary: 'Info', detail: data, closable: false }
                    
                  ]);
                  
                })
                .catch((error) => {
                  msgs.current?.clear();
                  msgs.current?.show([
                      { sticky: false, life: 100000, severity: 'error', summary: 'Erro', detail: 'Login ou Senha inválidos', closable: false }
                    
                  ]);
                })
              },1000);
    }
    
  }
  return (
    <>
    <div className='main'>
      <div>
        <div className='logo_login'>
          <img src={logoCreditBureau} alt="logo" className='imageLogo' />
        </div>
        <div className='card-login card'>
          <img src={logo} alt="logo" className='imageLogo' />
          <div  className="login_form">
            <Messages ref={msgs} />
            <h2>Solicitação de recuperação de senha</h2>
           
                <div className="flex-auto">
                    <label htmlFor="email" className="font-bold block mb-2 required">{t('inserirEmail')} </label>
                    <InputText id="email" onChange={(e)=>setEmail(e.target.value)} style={{width: '20rem'}} required></InputText>
                </div>
                
                <div style={{display:"flex", justifyContent:"start", paddingTop:"1rem"}} className=" flex ">
                  <Button style={{marginRight:"4rem", backgroundColor:'#183462'}} label="Recuperar Senha"  onClick={submit} />
                  <Button style={{color:'#183462'}}  outlined label="Voltar"  onClick={()=>{navigate("/")}} />
              </div> 
               
            
          </div>
        </div>
      </div>
    </div>
      
       
    </>
  )
}
