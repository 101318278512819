import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";
import VeiculoVendaList from './VeiculoVendaList';
import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import VeiculoVendaCadastrar from './VeiculoVendaCadastrar';
import { Messages } from 'primereact/messages';
import postVeiculoVenda from "../../service/postVeiculoVenda";
import putVeiculoVenda from '../../service/putVeiculoVenda';

export default function LeilaoPage() {
  const { t } = useTranslation();
  const msgs = useRef<Messages>(null);
  const [veiculoVenda, setVeiculoVenda] = useState<any | null>(null);
  const labelButton = t('cadastrar')
  const saveVeiculoVenda = (veiculoVenda:any) => {
    if(veiculoVenda.id){
      putVeiculoVenda(veiculoVenda).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setVeiculoVenda(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    else{
      postVeiculoVenda(veiculoVenda).then(data => {
        if(data.includes('Erro')){
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: data, closable: false }
               
            ]);
          }
        }
        else{
          setVeiculoVenda(null);
          if (msgs.current) {
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
               
            ]);
          }
        }
      })
    }
    
    
  }
  const startContent = (
    <React.Fragment>
        <Button label={labelButton} icon="pi pi-plus" style={{backgroundColor:'#183462'}} onClick={() => {setVeiculoVenda({})}}/>
    </React.Fragment>
);

  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-shopping-cart" style={{ fontSize: '2.0rem' }}></i> {t('comunicadoVendaVeiculo')}</h1>
            </div>     
          </div>
      </div>
      <div className="card">
        <Messages ref={msgs} />
        {!veiculoVenda && (
          <>
            <Toolbar start={startContent} />
            <br></br>
            <br></br>
            <VeiculoVendaList onEdit={(veiculoVenda:any) => { setVeiculoVenda(veiculoVenda);}}></VeiculoVendaList>
          </>
        )}
        {veiculoVenda && (
          <>
           <VeiculoVendaCadastrar msgs={msgs} veiculoVenda={veiculoVenda} onSave={saveVeiculoVenda} onClose={() => {setVeiculoVenda(null)}}></VeiculoVendaCadastrar>
          </>
        )}
      </div>
                
    </Container>
  )
}
